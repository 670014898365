import { useState } from "react";

export default function Cover(){

    const [render, set_render] = useState(new Date().getTime());
    const [token_table, set_token_table] = useState(null);
    const [number_of_cover, set_number_of_cover] = useState(null);
    const [table_name, set_table_name] = useState(null);

    return (
        <>
            <h4>Gestione coperti</h4>
            <p>Token tavolo</p>
            <input class="form-control" type={"text"} onChange={(e) => set_token_table(e.target.value)} value={token_table}/><br/>
            <p>Numero di coperti da aggiungere</p>
            <input class="form-control" type={"text"} onChange={(e) => set_number_of_cover(e.target.value)} value={number_of_cover}/><br/>
            <button className="btn btn-success" onClick={() => {
                if(token_table && !isNaN(number_of_cover)){
                    fetch(
                        'https://dybox.dylogapp.it/api/ea_pwa/table/add_cover',
                        {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                cover: number_of_cover,
                                token_table: token_table
                            })
                        }
                    ).then(async (result) => {

                        try{
                            if(result.status == 200){
                                const json = await result.json();
                                if(json.Result == "OK"){
                                    alert(number_of_cover + " coperti aggiunti!");
                                    set_token_table('');
                                    set_table_name('');
                                    set_number_of_cover('');
                                    return;
                                }
                            }
                        }catch(_){}
                        alert("Coperti non aggiunti!");

                    });
                }
            }}>Aggiungi coperto</button><br/><br/>
            <p>Nome del tavolo</p>
            <input class="form-control" type={"text"} onChange={(e) => set_table_name(e.target.value)} value={table_name}/><br/>
            <button className="btn btn-primary" onClick={() => {

                const lc = localStorage.getItem('ea_tables');
                let tables = (lc ? JSON.parse(lc) : []);
                if(token_table && table_name){
                    tables.push({
                        name: table_name,
                        token: token_table
                    });
                    localStorage.setItem('ea_tables', JSON.stringify(tables));
                    set_render(new Date().getTime());
                }

            }}>Ricorda tavolo</button>
            <hr/>
            <h4>Tavoli registrati</h4>
            {
                JSON.parse((localStorage.getItem('ea_tables') ?? "[]")).map((item, index) => 
                    <div key={index.toString()}>
                        <p style={{textDecoration: "underline"}}>Tavolo {item.name} / {item.token}</p>
                        <button onClick={() => {
                            set_token_table(item.token);
                        }} className="btn btn-primary">Usa</button>
                        <button onClick={() => {
                            const lc = JSON.parse(localStorage.getItem('ea_tables'));
                            const index = lc.findIndex(i => i.token == item.token);
                            lc.splice(index, 1);
                            if(index > -1){
                                localStorage.setItem('ea_tables', JSON.stringify(lc));
                                set_render(new Date().getTime());
                            }
                        }} className="btn btn-danger" style={{marginLeft: "10px"}}>Cancella</button>
                    </div>
                )
            }
        </>
    );

}