import { useState } from "react";

export default function LogOrder(){

    const [date, set_date] = useState(null);
    const [app, set_app] = useState(null);
    const [records, set_records] = useState(null);

    const get_orders = (date, app) => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({ "app_name": app, "day_order": date });
        
        const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };
        
        fetch("https://wpaservice.cmh.it/api/ea_pwa/table/get_log_orders", requestOptions)
        .then((response) => {
            if(response.status == 200){
                response.json().then((json) => {
                    if(json.Result == "OK"){
                        set_records(json.Json ?? []);
                    }else{
                        set_records(null);
                    }
                });
            }
        });

    };

    return (
        <>
            <h4>Log ordini</h4>
            <p>Data ordine</p>
            <input class="form-control" type={"date"} onChange={(e) => set_date(e.target.value)} value={date}/><br/>
            <p>Reference app</p>
            <input class="form-control" type={"text"} onChange={(e) => set_app(e.target.value)} value={app}/><br/>
            <button className="btn btn-primary" onClick={() => {
                get_orders(date, app);
            }}>Cerca</button>
            <hr/>
            {
                records &&  records.length > 0 ? 
                    <>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Fingerprint</th>
                                    <th>App</th>
                                    <th>Tavolo</th>
                                    <th>Data</th>
                                    <th>Ora</th>
                                    <th>Metodo</th>
                                    <th>Json</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    records.map((item, index) => 
                                        <tr key={index.toString()}>
                                            <td>{item.fingerprint}</td>
                                            <td style={{color: "green", fontWeight: "bold"}}>{item.app_name}</td>
                                            <td>{item.name_table}</td>
                                            <td>{item.day_order}</td>
                                            <td>{item.hour_order}</td>
                                            <td style={{color: "red", fontWeight: "bold"}}>{item.method}</td>
                                            <td>
                                                <button className="btn btn-primary" onClick={() => {
                                                    try{
                                                        console.log(JSON.parse(item.json_order));
                                                    }catch(_){}
                                                }}>Json in console</button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </>
                :
                    <></>
            }
        </>
    );
}