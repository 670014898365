import { useEffect, useState } from "react";
import Cover from "./cover";
import LogOrder from "./log_order";

export default function App(){
    
    const [render, set_render] = useState(new Date().getTime());

    useEffect(() => {
        
        window.addEventListener('hashchange', () => {
            set_render(new Date().getTime());
        });

    }, []);
    
    return (
        <div style={{padding: "20px"}}>
            {
                window.location.hash.split("#").join("") == "" ? 
                    <>
                        <p>Pannello amministrativo web app - Funzioni disponibili</p>
                        <button className="btn btn-primary" onClick={() => window.location.hash = "cover"}>Gestione coperti</button>
                        <br/><br/>
                        <button className="btn btn-primary" onClick={() => window.location.hash = "log_order"}>Log ordini</button>
                    </>
                :
                    window.location.hash.split("#").join("") == "cover" ? 
                        <Cover/>
                    :
                        window.location.hash.split("#").join("") == "log_order" ? 
                            <LogOrder/>
                        :
                            <></>
            }
        </div>
    );
}